import React, { Component } from "react";
import Slot from "./Slot";
import Hours from "./Hours";
import Stage from "./Stage";
import Now from "./Now";

function slotify(slots) {
  return slots.map((slot) => (
    <Slot
      key={slot.artist}
      name={slot.artist}
      start={slot.start}
      stop={slot.stop}
    />
  ));
}

class Day extends Component {
  render() {
    const day = this.props.day;
    return (
      <div className="App" id="Schedule">
        <Hours />
        <Stage name="Movement" slots={slotify(day["Movement"])} />
        <Stage name="Waterfront" slots={slotify(day["Waterfront"])} />
        <Stage name="Stargate" slots={slotify(day["Stargate"])} />
        <Stage name="Underground" slots={slotify(day["Underground"])} />
        <Stage name="Pyramid" slots={slotify(day["Pyramid"])} />
        <Stage name="Detroit" slots={slotify(day["Detroit"])} />
        <Now />
      </div>
    );
  }
}

export default Day;
