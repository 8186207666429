import React, { Component } from "react";
import Times from "./Times";

function time2float(time) {
  const parts = time.split(":");
  return parseFloat(parts[0]) + parseFloat(parts[1]) / 60;
}

function time2height(start, stop) {
  return ((stop - start) * 10).toString() + "vh";
}

function time2vh(time) {
  return ((time - 13) * 10).toString() + "vh";
}

function getRank(id) {
  const rank = localStorage.getItem(id);
  if (rank) {
    return rank;
  } else {
    return "Slot rank0";
  }
}

function changeRank(id) {
  const slot = document.getElementById(id);
  if (slot.className === "Slot rank0") {
    slot.className = "Slot rank1";
    localStorage.setItem(id, "Slot rank1");
  } else {
    if (slot.className === "Slot rank1") {
      slot.className = "Slot rank2";
      localStorage.setItem(id, "Slot rank2");
    } else {
      if (slot.className === "Slot rank2") {
        slot.className = "Slot rank0";
        localStorage.removeItem(id);
      }
    }
  }
}

class Slot extends Component {
  render() {
    const artist = this.props.name;
    const start = time2float(this.props.start);
    const stop = time2float(this.props.stop);
    const height = time2height(start, stop);
    const top = time2vh(start);
    const divStyle = {
      height: height,
      top: top,
    };
    const rank = getRank(artist);
    const times = <Times start={this.props.start} stop={this.props.stop} />;

    return (
      <div
        id={artist}
        className={rank}
        style={divStyle}
        onClick={() => changeRank(artist)}
      >
        {artist} {times}
      </div>
    );
  }
}

export default Slot;
