import React, { Component } from "react";
import Today from "./Today";

class Menu extends Component {
  render() {
    const setDay = this.props.changeday;
    const setSat = () => {
      setDay("Sat");
      document.getElementById("today").innerText = "Sat";
    };
    const setSun = () => {
      setDay("Sun");
      document.getElementById("today").innerText = "Sun";
    };
    const setMon = () => {
      setDay("Mon");
      document.getElementById("today").innerText = "Mon";
    };
    return (
      <div className="Menu">
        <div className="MenuItem" onClick={() => setSat()}>
          Sat
        </div>
        <div className="MenuItem" onClick={() => setSun()}>
          Sun
        </div>
        <div className="MenuItem" onClick={() => setMon()}>
          Mon
        </div>
        <Today day="Sat" />
      </div>
    );
  }
}

export default Menu;
