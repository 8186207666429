import React, { Component } from "react";
import Hour from "./Hour";

class Hours extends Component {
  render() {
    let hours = [];
    for (var i = 2; i < 13; i++) {
      hours.push(<Hour hour={i} key={i} />);
    }
    return <div className="Hours">{hours}</div>;
  }
}

export default Hours;
