import React, { Component } from "react";

class Today extends Component {
  render() {
    return (
      <div id="today" className="Today">
        {this.props.day}
      </div>
    );
  }
}

export default Today;
