import React, { Component } from "react";

function time2vh(time) {
  return ((time - 13) * 10).toString() + "vh";
}

class Now extends Component {
  state = {
    time: new Date(),
  };

  componentDidMount() {
    this.intervalID = setInterval(() => {
      this.setState({ time: new Date() });
    }, 60 * 1000); // Update every minute
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }
  render() {
    const now = this.state.time;
    const time = now.getHours() + now.getMinutes() / 60;
    const top = time2vh(time);
    //    const top = 500 + now.getSeconds(); // for testing
    const divStyle = {
      top: top,
    };

    return <div className="Now" style={divStyle}></div>;
  }
}

export default Now;
