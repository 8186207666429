import React, { Component } from "react";
import StageName from "./StageName";

class Stage extends Component {
  render() {
    return (
      <div className="Stage">
        <StageName name={this.props.name} />
        {this.props.slots}
      </div>
    );
  }
}

export default Stage;
