import React, { Component } from "react";

function time2time(time) {
  const [hour, min] = time.split(":");
  if (hour < 13) {
    return [hour, min].join(":") + " AM";
  } else {
    return [hour - 12, min].join(":");
  }
}

class Times extends Component {
  render() {
    const start = time2time(this.props.start);
    const stop = time2time(this.props.stop);
    return (
      <div className="Times">
        {start} - {stop}
      </div>
    );
  }
}

export default Times;
