import "./App.css";
import Menu from "./Menu";
import Day from "./Day";
import React, { useState } from "react";

function App() {
  const sets = require("./sets.json");
  const [day, setDay] = useState("Sat");

  return (
    <div>
      <Menu changeday={setDay} />
      <Day day={sets[day]} />
    </div>
  );
}

export default App;
